<template>
  <div
    v-loading="isBusy || deleting"
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <div class="container mt-3">
      <BreadCrumbComponent :pageTitles="['Gallery Categories', 'Sections']" />
    </div>
    <article class="mt-3">
      <section>
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Gallery Category Sections</strong><br />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div style="margin-top: 15px">
                <el-input
                  placeholder="Search by Title..."
                  v-model="search"
                  class="input-with-select"
                >
                </el-input>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </article>

    <section>
      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article
        v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px"
      >
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div class="pt-2" style="font-weight: 400; font-size: 0.9em">
            Failed to fetch sections now. Please try again
          </div>
          <button
            type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getCategorySections"
            style="
              width: 220px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            "
          >
            <span style="position: relative; bottom: 1px"
              ><i class="pr-2 fas fa-refresh"></i> Try Again</span
            >
          </button>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING TEAMS FROM THE SERVER --->
      <article v-show="loading && !isLoadingError" class="">
        <div class="container">
          <div class="sections-grid">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!loading && !isLoadingError">
        <div class="container">
          <div class="sections-grid">
            <div
              v-for="(section, index) in filteredCategorySections"
              :key="index"
              class="section-container"
            >
              <div class="section-container_content">
                <div>
                  <div class="publish_content">
                    <span class="el-icon-folder-opened"></span>
                  </div>
                  <div class="publishing_status">
                    <el-button
                      :type="
                        section.is_published == true ? 'success' : 'danger'
                      "
                      @click="
                        changePublishStatus(
                          section.is_published,
                          section.website_gallery_category_section_id
                        )
                      "
                      size="mini"
                    >
                      {{
                        section.is_published == true
                          ? "Published"
                          : "Not Published"
                      }}
                    </el-button>
                  </div>
                </div>
                <div>
                  <div>{{ section.name }}</div>
                  <div>
                    <el-button
                      type="primary"
                      round
                      size="mini"
                      class="pl-4 pr-4"
                      @click="
                        $router.push({
                          name: `GalleryCategoryItems`,
                          params: {
                            galleryCategorySectionId: `${section.website_gallery_category_section_id}`,
                          },
                        })
                      "
                      style="cursor: pointer"
                      >Photos</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="section-btns">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="section.orderIndex == 0"
                    @click="moveGallerySectionUp(section.website_gallery_category_section_id, section.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                  icon="el-icon-bottom"
                  size="mini"
                  circle
                  :disabled="section.orderIndex == (galleryCategorySections.length - 1)"
                  @click="moveGallerySectionDown(section.website_gallery_category_section_id, section.orderIndex)"></el-button>
                </el-tooltip>
                <el-button
                  type="warning"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="
                    editSection(
                      section.website_gallery_category_section_id
                    )
                  "
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="
                    deleteSection(section.website_gallery_category_section_id)
                  "
                ></el-button>
              </div>
            </div>
            <AddSection @on-refresh="getCategorySections" />
          </div>
        </div>
      </article>
    </section>

    <EditSectionDialog
      :isEditSectionVisible="isEditSectionVisible"
      :sectionId="sectionId"
      @on-refresh="getCategorySections"
      @on-close-dialog="closeEditSectionDialog"
    />
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddSection from "../components/dialogs/add-gallery-category-section-dialog.vue";
import EditSectionDialog from "../components/dialogs/edit-gallery-category-section-dialog.vue";
export default {
  components: {
    AddSection,
    EditSectionDialog,
    BreadCrumbComponent,
  },

  data() {
    return {
      loading: true,
      isLoadingError: false,
      isBusy: false,
      search: "",
      isEditSectionVisible: false,
      sectionId: "",
      submitting: false,
      deleting: false,
      galleryCategorySections: [],
    };
  },
  computed: {
    filteredCategorySections: function () {
      return this.galleryCategorySections.filter((section) => {
        return section.name.match(new RegExp(this.search, "i"));
      });
    },
  },

  mounted() {
    this.getCategorySections();
  },

  methods: {
    async getCategorySections() {
      try {
        this.loading = true;
        this.isLoadingError = false;
        let request = await this.$http.get(`website/gallery/${this.$route.params.galleryCategoryId}/category-sections`);
        if (
          request.data.success &&
          request.data.message == "CATEGORY SECTIONS FETCHED SUCCESSFULLY"
        ) {
          this.galleryCategorySections = request.data.categories.map((item, index) => {
            return {
              website_gallery_category_section_id: item.website_gallery_category_section_id,
              name: item.name,
              is_published: item.is_published,
              orderIndex: index,
            }
          });
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isLoadingError = true;
        return this.showFailedMessage(
          "Unable to Fetch Sections",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
        // this.isLoadingError = false;
      }
    },

    editSection(sectionId) {
      this.sectionId = sectionId;
      this.isEditSectionVisible = true;
    },

    closeEditSectionDialog() {
      this.isEditSectionVisible = false;
    },

    async deleteSection(sectionId) {
      await this.$confirm(
        "This will permanently delete this Section. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.deleting = true;
        let request = await this.$http.delete(
          `website/gallery/category-sections/${sectionId}`
        );
        if (
          request.data.success &&
          request.data.message == "CATEGORY SECTION DELETED SUCCESSFULLY"
        ) {
          this.getCategorySections();
          this.deleting = false;
          return this.showSuccessMessage(
            "Deleted Successfully",
            "The Category section was successfully deleted"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage("Operation Failed", "Unable to delete Section");
      } finally {
        this.deleting = false;
      }
    },

    async changePublishStatus(is_published, sectionId) {
      await this.$confirm(
        `Are you sure you want to change the publish status?`,
        "Confirm Changes",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.patch(
          `website/gallery/category-sections/${sectionId}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message ==
            "CATEGORY SECTION PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getCategorySections();
          return this.showSuccessMessage(
            "Changes Saved Successfully",
            "The Publish Status was successfully changed"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY NOT FOUND") {
            this.showWarningMessage(
              "Not Found",
              "Unable to find the chosen category"
            );
            return this.getCategorySections();
          }
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable To Change Category Publish Status"
        );
      } finally {
        this.isBusy = false;
      }
    },

    
    moveGallerySectionUp(categorySectionId, categorySectionOrderIndex) {
      // get previous section as we want to swap with it
      const affectedCategorySection = this.galleryCategorySections.filter(item => item.website_gallery_category_section_id == categorySectionId)[0];
      const currentIndex = affectedCategorySection.orderIndex;

      const prevCategorySection = this.galleryCategorySections.filter(item => item.orderIndex == (categorySectionOrderIndex - 1))[0]

      // swap with previous section
      affectedCategorySection.orderIndex = prevCategorySection.orderIndex
      prevCategorySection.orderIndex = currentIndex

      this.saveGalleryCategorySectionsOrder()
    },

    moveGallerySectionDown(categorySectionId, categorySectionOrderIndex) {
      // get previous section as we want to swap with it
      const affectedCategorySection = this.galleryCategorySections.filter(item => item.website_gallery_category_section_id == categorySectionId)[0];
      const currentIndex = affectedCategorySection.orderIndex;

      const prevCategorySection = this.galleryCategorySections.filter(item => item.orderIndex == (categorySectionOrderIndex + 1))[0]

      // swap with previous section
      affectedCategorySection.orderIndex = prevCategorySection.orderIndex
      prevCategorySection.orderIndex = currentIndex

      this.saveGalleryCategorySectionsOrder()
    },

    async saveGalleryCategorySectionsOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/save-order-indexes",
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "isLoadingError",
        showSuccessMessage: true,
        body: {
          itemType: "WEBSITE_GALLERY_CATEGORY_SECTIONS",
          items: this.galleryCategorySections.map(item => {
            return {
              id: item.website_gallery_category_section_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Items order saved successfully"
      ) {
        this.getCategorySections();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.sections-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.sections-grid > div {
  height: 300px;
}
.sections-grid > .section-container {
  position: relative;
}
.sections-grid > .section-container .section-btns {
  display: none;
}
.sections-grid > .section-container:hover .section-btns {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}
.section-btns .el-icon-edit {
  font-size: 1.5em;
  margin-right: 10px;
  color: orange;
  cursor: pointer;
}
.section-btns .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.sections-grid > .section-container .section-container_content {
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.sections-grid > .section-container .section-container_content > div {
  width: 100%;
}

.sections-grid
  > .section-container
  .section-container_content
  > div:first-child {
  border-radius: 5px 5px 0px 0px;
  height: 200px !important;
  background-color: #2a70b5a0;
  color: white;
  position: relative;
}

.sections-grid
  > .section-container
  .section-container_content
  .publish_content {
  height: 200px;
  width: 100%;
  font-size: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sections-grid
  > .section-container
  .section-container_content
  .publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sections-grid
  > .section-container
  .section-container_content
  > div:nth-child(2) {
  text-align: center;
  background-color: white;
  color: black;
  padding-top: 20px;
  border-radius: 0px 0px 5px 5px;
  height: 100%;
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .sections-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .sections-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>
