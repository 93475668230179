<template>
  <div>
    <div class="addDialogTrigger z-depth-0" @click="openAddSectionDialog">
      <span class="el-icon-plus"></span>
    </div>
    <section>
      <div class="container">
        <div class="section-dialog">
          <el-dialog
            title="Add Gallery Category Section"
            :visible.sync="addSectionDialog"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submitting">
              <el-form
                :model="categorySectionForm"
                label-position="top"
                :rules="teamRules"
                ref="categorySectionForm"
                label-width="120px"
                onSubmit="return false;"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Name" prop="name">
                      <el-input
                        v-model="categorySectionForm.name"
                        @keyup.enter.native="
                          addCategorySection('categorySectionForm')
                        "
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="addCategorySection('categorySectionForm')"
                    >Add Section</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addSectionDialog: false,
      submitting: false,
      categorySectionForm: {
        name: "",
      },
      teamRules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    openAddSectionDialog() {
      this.addSectionDialog = true;
    },

    handleClose(done) {
      this.clearInputs();
      done();
    },

    clearInputs() {
      this.categorySectionForm.name = "";
    },

    async addCategorySection(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const response = await this.$http.post(
              `website/gallery/category-sections`,
              {
                name: this.categorySectionForm.name,
                website_gallery_category_id:
                  this.$route.params.galleryCategoryId,
              }
            );
            if (
              response.data.success == true &&
              response.data.message == "CATEGORY SECTION ADDED SUCCESSFULLY"
            ) {
              this.$emit("on-refresh");
              this.addSectionDialog = false;
              this.submitting = false;
              this.clearInputs();
              return this.showSuccessMessage(
                "Added Successfully",
                "Section successfully created."
              );
            } else {
              throw "UNEXPECTED API RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            }
            this.showFailedMessage(
              "Unexpected Error",
              "An unexpected error occurred. Please try again"
            );
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.section-dialog .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .section-dialog .el-dialog {
    width: 50%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .section-dialog .el-dialog {
    width: 70%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .section-dialog .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .section-dialog .el-dialog {
    width: 90%;
  }
}
</style>
