<template>
    <div>
      <section>
        <div class="container">
          <div class="team_dialogs">
            <el-dialog
              title="Edit Gallery Category"
              :visible="isEditSectionVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              @open="getOneGalleryCategory"
            >
              <div v-loading="submitting || isLoading">
                <el-form
                  :model="categorySectionForm"
                  label-position="top"
                  :rules="teamRules"
                  ref="categorySectionForm"
                  label-width="120px"
                  onSubmit="return false;"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Name"
                        prop="name"
                      >
                        <el-input
                          v-model="categorySectionForm.name"
                          @keyup.enter.native="editGalleryCategory('categorySectionForm')"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <el-form-item class="text-center">
                    <el-button
                      type="primary"
                      @click="editGalleryCategory('categorySectionForm')"
                    >Edit Category Section</el-button>
                  </el-form-item>
                </el-form>  
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    props: {
      sectionId: {
        type: String,
        required: true,
      },
      isEditSectionVisible: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        submitting: false,
        isLoading: false,
        categorySection: {},
        categorySectionForm: {
          name: "",
        },
        teamRules: {
          name: [
            {
              required: true,
              message: "Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
    
    methods: {
      handleClose(done) {
        done();
        this.$emit("on-close-dialog");
      },
      async editGalleryCategory(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.submitting = true;
            try {
              const response = await this.$http.patch(
                `website/gallery/category-sections/${this.categorySection.website_gallery_category_section_id}`,
                {
                  name: this.categorySectionForm.name,
                }
              );
              if (
                response.data.success == true &&
                response.data.message == "CATEGORY SECTION UPDATED SUCCESSFULLY"
              ) {
                this.$emit("on-refresh");
                this.$emit("on-close-dialog");
                this.submitting = false;
                return this.showSuccessMessage(
                  "Section Updated",
                  "The category Section was successfully updated"
                );
              } else {
                throw "UNEXPECTED API RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.showFailedMessage(
                  "Connection failed",
                  "A network error occurred, please try again."
                );
              } 
              this.showFailedMessage(
                  "Unexpected Error",
                  "An unexpected error occurred. Please try again"
                );
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
      },

      async getOneGalleryCategory() {
        try {
          this.isLoading = true;
          this.loadingError = false;
          let request = await this.$http.get(
            `website/gallery/category-sections/${this.sectionId}`
          );
          if (
            request.data.success &&
            request.data.message == "CATEGORY SECTION FETCHED SUCCESSFULLY"
          ) {
            this.categorySection = request.data.category;
            this.categorySectionForm.name = this.categorySection.name;
          } else {
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loadingError = true;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.showFailedMessage(
            "Operation Failed",
            "Unable to Fetch Gallery Category Section"
          );
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  .addDialogTrigger {
    border: 1px dashed black;
    border-radius: 5px;
    height: 100%;
    font-size: 3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  
  <style>
  .team_dialogs .el-dialog {
    width: 40%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .team_dialogs .el-dialog {
      width: 50%;
    }
  }
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .team_dialogs .el-dialog {
      width: 70%;
    }
  }
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .team_dialogs .el-dialog {
      width: 90%;
    }
  }
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (max-width: 480px) {
    .team_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>